<template>
    <div>
      <!--Breadcrumb-->
      <section>
        <Breadcrumb
          :title="info.searchItem"
          :array="[
            { islink: true, url: '/', text: 'Ana Sayfa' },
            { isCity: true },
          ]"
          :scity="true"
        />
      </section>
      <!--/Breadcrumb-->
  
      <!--Add listing-->
      <section class="sptb">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12" id="mobileEx">
              <!--Add lists-->
              <div class="card-body">
                <ProfileItem :list="list" :isLoad="isExpertLoad" />
                <div class="center-block text-center mt-5">
                  <ul class="pagination mb-5" v-if="isExpertLoad">
                    <li class="skeleton page-item page-prev w-8 p-4"></li>
                    <li
                      v-for="(s, i) in 5"
                      :key="i"
                      style="margin-left: 5px"
                      class="skeleton page-item w-6 p-4"
                    ></li>
                    <li
                      style="margin-left: 5px"
                      class="skeleton page-item page-next w-8 p-4"
                    ></li>
                  </ul>
  
                  <pagination
                    :preload="'/search?q=' + search + '&city='+city+'&page='"
                    v-if="!isExpertLoad"
                    :currentpage="currentpage"
                    :pagesize="pagesize"
                  />
                </div>
              </div>
              <!--Add lists-->
            </div>
          </div>
        </div>
      </section>
      <!--Add Listing-->
    </div>
  </template>
  <style scoped>
  @media screen and (max-width: 990px) {
    #mobileEx {
      padding-left: 35px;
      padding-right: 35px;
    }
  }
  </style>
  <script>
  import ProfileItem from "./Components/ProfileItem.vue";
  import Breadcrumb from "../../components/Breadcrumb.vue";
  import Pagination from "../../components/Pagination.vue";
  export default {
    metaInfo() {
      return {
        title: this.title,
        meta: [
          { name: "robots", content: "noindex" },
          { name: "googlebot", content: "noindex" },
          { charset: "utf-8" },
          {
            name: "description",
            content: this.description,
          },
        ],
      };
    },
    created() {
      this.$store.state.header = true;
      this.$store.state.footer = true;
      if(this.$route.query.page!=null){
        this.currentpage = this.$route.query.page;
      }
      
      this.getList();
    },
    data() {
      return {
        info: [],
        list: [],
        currentpage: 1,
        perpage: 9,
        totalsize: 0,
        pagesize: 0,
        isExpertLoad: true,
        title: "",
        description: "",
        search:"",
        city:""
      };
    },
    watch: {
      "$route.params.page": function () {
        this.currentpage = this.$route.params.page;
        this.getList();
      },
      "$route.query.page": function () {
        this.currentpage = this.$route.query.page;
        this.getList();
      },
    },
    methods: {
      getList() {
        let experienceInfo;
        if (this.$route.query.q != null && this.$route.query.city != null) {
          this.search=this.$route.query.q;
          this.city=this.$route.query.city
          experienceInfo = {
            search: this.search,
            location: this.city,
            begin: (this.currentpage - 1) * this.perpage,
            perpage: this.perpage,
          };
        } else {
          if (
            this.$route.params.search != null &&
            this.$route.params.city != null
          ) {
            this.search=this.$route.params.search;
            this.city=this.$route.query.city
            experienceInfo = {
              search: this.search,
              location: this.city,
              begin: (this.currentpage - 1) * this.perpage,
              perpage: this.perpage,
            };
          }
  
          if (this.$route.params.search != null && !this.$route.params.city) {
            this.search=this.$route.params.search;
            experienceInfo = {
              search: this.search,
              begin: (this.currentpage - 1) * this.perpage,
              perpage: this.perpage,
            };
          }
          if (!this.$route.params.search && !this.$route.params.city != null) {
            this.city=this.$route.query.city
            experienceInfo = {
              location: this.city,
              begin: (this.currentpage - 1) * this.perpage,
              perpage: this.perpage,
            };
          }
        }
  
        this.$store
          .dispatch("serviceUserFilter", experienceInfo)
          .then((value) => {
            this.info = value.info;
            this.list = value.users;
            this.totalsize = value.size;
            this.pagesize = value.pages;
            this.isExpertLoad = false;
            this.title =
            (value.info.city!=null?value.info.city+' ':'')+(value.info.searchItem != null
                ? value.info.searchItem:'') + " - Bilgi, uzmanları ve terapistleri, sıkça sorulan sorular";
            this.description =
            (value.info.city!=null?value.info.city+' bölgesinde ':'')+(value.info.searchItem != null
                ? value.info.searchItem:'') + " - Türkiye’deki en iyi uzmanları, terapistleri bulun ve sorunlarınızı uzmanlara sorarak çözün.";
  
            let _this = this;
            $(document).ready(function () {
              let content = document.getElementById("appcontent").innerHTML;
              _this.$store.dispatch("setMetaContent", [
                _this.title,
                _this.description,
                "",
                "",
                "",
                content,
              ]);
            });
          });
      },
  
      backToTop() {
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          0
        );
      },
    },
    components: { ProfileItem, Breadcrumb, Pagination },
    mounted() {
      this.backToTop();
    },
  };
  </script>